import React, { useEffect, useState } from "react";

const Posts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch("https://social-media.relatedtechnetwork.workers.dev/posts")
      .then(async (response) => {
        let posts = await response.json();
        setPosts(posts);
      })
      .catch((error) => {
        console.log(`ERR: ${error}`);
      });
  }, []);

  return (
    <div>
      {posts.map((post) => (
        <div className="post" key={post.title}>
          <h3 className="post_title">
            {post.title} | {post.username}
          </h3>
          <hr></hr>
          <p className="post_content">{post.content}</p>
        </div>
      ))}
    </div>
  );
};

export default Posts;
