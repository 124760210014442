import "./App.css";
import Posts from "./components/posts";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="header-text">Social Media</h1>
      </header>
      <h2 className="posts-text">Posts</h2>
      <body>
        <Posts className="posts" />
      </body>
    </div>
  );
}

export default App;
